import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"
import ContentContainer, {
  CenterBox,
  PageHeading,
} from "../components/ContentContainer"
import SEO from "../components/SEO"
import Layout from "../components/layout"

const Success = () => {
  return (
    <Layout>
      <SEO title="Sucess!" />
      <ContentContainer className="flex-grow pt-20">
        <CenterBox className="p-10 text-xl">
          <PageHeading className="">Thanks for Contacting Us!</PageHeading>
          <p className="mt-5 ">Your message was successfully sent.</p>
          <p> We'll be in touch with you soon.</p>
          {/* <div to="/" className="btn fit-content"> */}

          <Link to="/" className="btn mt-5 ">
            Home
          </Link>
          {/* </div> */}
        </CenterBox>
      </ContentContainer>
    </Layout>
  )
}

export default Success
