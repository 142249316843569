import React from "react"

const ContentContainer = ({ children, className }) => {
  return (
    <div
      className={`flex flex-col items-center bg-gray-300 text-center py-10 ${className}`}
    >
      {children}
    </div>
  )
}

export const CenterBox = ({ children, className }) => {
  return (
    <div
      className={`bg-white w-10/12 shadow-md flex flex-col items-center justify-center ${className}`}
    >
      {children}
    </div>
  )
}

export const PageHeading = ({ children }, { className }) => (
  <h1 className={`${className}`}>{children}</h1>
)

export default ContentContainer
